import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import helper from '@/utils/helper';
import authApi from '@/api/auth';

const authority = helper.getParsedJsonSafe(localStorage.getItem('authority'));
const token = helper.getParsedJsonSafe(localStorage.getItem('token'));
const email = helper.getParsedJsonSafe(localStorage.getItem('email'));
const name = helper.getParsedJsonSafe(localStorage.getItem('name'));
const platformUserId = helper.getParsedJsonSafe(localStorage.getItem('platformUserId'));
const userId = helper.getParsedJsonSafe(localStorage.getItem('userId'));
const companyId = helper.getParsedJsonSafe(localStorage.getItem('companyId'));
const menuPermission = helper.getParsedJsonSafe(localStorage.getItem('menuPermission'));
const isLoginMimic = localStorage.getItem('isLoginMimic') ? true : false;

const getRedirectUrl = user => {
  if (['admin', 'manager'].includes(user.authority)) {
    return '/home';
  }

  if (['owner'].includes(user.authority)) {
    return '/p/report';
  }

  if (user.menuPermission?.includes('report')) {
    return '/p/report';
  }

  if (['apps', 'finance', 'campaign'].includes(user.menuPermission?.[0])) {
    return `/p/${user.menuPermission?.[0]}`;
  }

  return `/user`; // fallback
};

export default new Vuex.Store({
  state: {
    authority: authority || '',
    email: email || '',
    name: name || '',
    platformUserId: platformUserId || '',
    userId: userId || '',
    companyId: companyId || '',
    token: token || '',
    menuPermission: menuPermission || [],
    reportSelectedTab: 0,
    isLoginMimic: isLoginMimic || false,
  },
  getters: {
    isLogin(state) {
      return state.userId !== '';
    },
    isLoginedUser: state => (companyId, userId) => {
      return state.companyId == companyId && state.userId == userId;
    },
    isInhouseUser: state => {
      return ['manager', 'admin'].includes(state?.authority);
    },
    companyId: state => {
      return state?.companyId;
    },
    userId: state => {
      return state?.userId;
    },
    authority: state => {
      return state?.authority;
    },
    hasReportPermission: state => {
      if (state.authority === 'owner') return true;
      return state.menuPermission?.includes('report') ? true : false;
    },
    hasMenuPermission: state => menu => {
      if (menu === 'campaign') {
        switch (state.companyId) {
          case '663c94cb0dd75f0001000a7a': // 넵튠 MEEFF
          case '588977f69f0a52000c9c8f6f': // 넵튠 공통플랫폼실
          case '64215004995b680001000001': // 넵튠
            return true;
          default:
            return false;
        }
      }

      if (state.authority === 'owner') return true;
      return state.menuPermission?.includes(menu) ? true : false;
    },
    getRedirectUrl: state => {
      return getRedirectUrl({
        authority: state.authority,
        menuPermission: state.menuPermission,
      });
    },
    getReportSelectedTab: state => {
      return state?.reportSelectedTab;
    },
  },
  mutations: {
    setAuthority(state, authority) {
      state.authority = authority;
    },
    clearAuthority(state) {
      state.authority = '';
    },
    setEmail(state, email) {
      state.email = email;
    },
    clearEmail(state) {
      state.email = '';
    },
    setPlatformUserId(state, userId) {
      state.platformUserId = userId;
    },
    clearPlatformUserId(state) {
      state.platformUserId = '';
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    clearUserId(state) {
      state.userId = '';
    },
    setName(state, name) {
      state.name = name;
    },
    clearName(state) {
      state.name = '';
    },
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = '';
    },
    setCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    clearCompanyId(state) {
      state.companyId = '';
    },
    setMenuPermission(state, menuPermission) {
      state.menuPermission = menuPermission;
    },
    clearMenuPermission(state) {
      state.menuPermission = [];
    },
    setReportSelectedTab(state, reportSelectedTab) {
      state.reportSelectedTab = reportSelectedTab;
    },
    clearReportSelectedTab(state) {
      state.reportSelectedTab = 0;
    },
    setIsLoginMimic(state, isLoginMimic) {
      state.isLoginMimic = isLoginMimic;
    },
  },
  actions: {
    LOGIN({ commit }, data) {
      commit('setAuthority', data?.user?.authority);
      commit('setToken', data.sessionToken);
      commit('setEmail', data?.user?.email);
      commit('setPlatformUserId', data?.user?.userId);
      commit('setUserId', data?.user?.userId);
      commit('setName', data?.user?.name);
      commit('setCompanyId', data?.user?.companyId);
      commit('setMenuPermission', data?.user?.menuPermission);

      localStorage.setItem('authority', JSON.stringify(data?.user?.authority));
      localStorage.setItem('token', JSON.stringify(data.sessionToken));
      localStorage.setItem('email', JSON.stringify(data?.user?.email));
      localStorage.setItem('platformUserId', JSON.stringify(data?.user?.userId));
      localStorage.setItem('userId', JSON.stringify(data?.user?.userId));
      localStorage.setItem('name', JSON.stringify(data?.user?.name));
      localStorage.setItem('companyId', JSON.stringify(data?.user?.companyId));
      localStorage.setItem('menuPermission', JSON.stringify(data?.user?.menuPermission));

      if (data?.passwordChangeCount === 0) {
        return '/user?t=i';
      }

      return getRedirectUrl(data?.user);
    },
    async LOGINEMAIL({ dispatch }, userData) {
      const { data } = await authApi.loginEmail(userData);
      return dispatch('LOGIN', data);
    },
    async LOGINMIMIC({ commit, dispatch }, { companyId, userId }) {
      const { data } = await authApi.loginMimic(companyId, userId);
      commit('setIsLoginMimic', true);
      localStorage.setItem('isLoginMimic', true);
      return dispatch('LOGIN', data);
    },
    MODIFYUSERNAME({ commit }, userData) {
      commit('setName', userData?.name);
      localStorage.setItem('name', JSON.stringify(userData.name));
    },
    LOGOUT({ commit }) {
      commit('clearAuthority');
      commit('clearToken');
      commit('clearEmail');
      commit('clearPlatformUserId');
      commit('clearUserId');
      commit('clearName');
      commit('clearCompanyId');
      commit('clearMenuPermission');

      localStorage.removeItem('authority');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('platformUserId');
      localStorage.removeItem('userId');
      localStorage.removeItem('name');
      localStorage.removeItem('companyId');
      localStorage.removeItem('menuPermission');
    },
    async LOGOUTMIMIC({ commit }) {
      const { data } = await authApi.logout();
      commit('setIsLoginMimic', false);
      localStorage.removeItem('isLoginMimic');
      this.dispatch('LOGOUT');
      return this.dispatch('LOGIN', data);
    },
    async authorityChange({ commit }, authority) {
      commit('setAuthority', authority);
      localStorage.setItem('authority', JSON.stringify(authority));
    },
  },
});

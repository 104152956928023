<script>
import bus from '@/utils/bus';
import { useRouter } from 'vue2-helpers/vue-router';

export default {
  name: 'AlertPopupCommon',
  components: {},
  setup(props) {
    const router = useRouter();
    function clickCancel() {
      if (props?.options?.eventCancelInterfaceName?.length) {
        this.$emit(props?.options?.eventCancelInterfaceName);
      }
      bus.closeAlertPopup();
    }

    function clickOk() {
      if (props?.options?.eventOkInterfaceName?.length) {
        if (props?.options?.eventOkInterfaceCustomEmit !== undefined) {
          props?.options?.eventOkInterfaceCustomEmit(props?.options?.eventOkInterfaceName, props?.options?.eventOkInterfaceArgs);
        } else {
          this.$emit(props?.options?.eventOkInterfaceName, props?.options?.eventOkInterfaceArgs);
        }
      }
      if (props?.options?.redirectUrl) {
        router.push(props?.options?.redirectUrl);
      }
      bus.closeAlertPopup();
    }

    return {
      clickCancel,
      clickOk,
    };
  },

  props: {
    showAlertPopup: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({
        show: false,
        message: '',
        showBtnCancel: false,
        showBtnOk: true,
        btnCancelTitle: '취소',
        vtnOkTitle: '확인',
        eventOkInterfaceName: '',
        eventCancelInterfaceName: '',
        redirectUrl: '',
      }),
    },
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <v-dialog v-model="showAlertPopup" max-width="420" persistent>
    <v-card :class="options?.message?.length > 60 ? 'alert-pop --dense' : 'alert-pop'">
      <v-card-text v-if="options.message" class="alert-txt">{{ options.message }}</v-card-text>
      <v-card-text v-if="options.html" class="alert-txt"><div v-html="options.html"></div></v-card-text>
      <v-card-actions class="alert-btns">
        <v-btn v-if="options.showBtnCancel" x-large color="#7D7D7D" @click="clickCancel">
          취소
        </v-btn>
        <v-btn x-large color="primary" @click="clickOk">
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "420",
      "persistent": ""
    },
    model: {
      value: _vm.isShow,
      callback: function ($$v) {
        _vm.isShow = $$v;
      },
      expression: "isShow"
    }
  }, [_c('v-alert', {
    staticClass: "loading-pop",
    attrs: {
      "value": true
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _c('div', {
    staticClass: "loading-txt"
  }, [_vm._v(_vm._s(_vm.message))])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
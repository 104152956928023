import Vue from 'vue';

const vue = new Vue();

function startSpinner(message) {
  vue.$emit('start:spinner', message);
}

function stopSpinner() {
  vue.$emit('stop:spinner');
}

function openAlertPopup(options) {
  vue.$emit('open:alert:popup', options);
}

function closeAlertPopup() {
  vue.$emit('close:alert:popup');
}

function showSnackbar(options) {
  vue.$emit('show:snackbar', options);
}

function hideSnackbar() {
  vue.$emit('hide:snackbar');
}

function openConfirmPopup(options) {
  vue.$emit('open:confirm:popup', options);
}

function closeConfirmPopup() {
  vue.$emit('close:confirm:popup');
}

export default {
  startSpinner,
  stopSpinner,
  openAlertPopup,
  closeAlertPopup,
  showSnackbar,
  hideSnackbar,
  openConfirmPopup,
  closeConfirmPopup,
  vue,
};

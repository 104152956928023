import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#FF5A4D',
				secondary: '#A1A1A1',
				secondary2: '#D4D4D4',
				greyDarken: '#7D7D7D',
			},
		},
	},
});

import store from '@/store/index';
import bus from '@/utils/bus';
import router from '@/routes';

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function(config) {
      config.headers.Authorization = `Bearer ${store.state.token}`;
      return config;
    },
    function(error) {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      const errorData = error?.response?.data;
      if (!errorData) {
        router.push({ path: '/notFoundPage' });
        return new Promise(() => {});
      }

      // 세션 만료 시 로그아웃 후 로그인 페이지로 redirection
      if (errorData?.code === '1005' && errorData?.message == 'InvalidSessionTokenError') {
        bus.openAlertPopup({ html: '세션이 만료되었습니다. <br/>다시 로그인해주세요.', redirectUrl: '/login' });
        store.dispatch('LOGOUT');

        return new Promise(() => {});
      } else if (errorData?.code === '1005' || errorData?.code === '1000') {
        // 비정상적인 요청 시 오류 페이지로 redirection
        router.push({ path: '/notFoundPage' });
        return new Promise(() => {});
      }

      return Promise.reject(error);
    },
  );
  return instance;
}

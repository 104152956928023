import { instance } from './index';

//Get notifications
function getNotifications(companyId) {
  return instance.get(`dashboard/companies/${companyId}/notifications`);
}

//Update notifications
function updateNotifications(companyId, notificationId) {
  return instance.post(`dashboard/companies/${companyId}/notifications/${notificationId}/skip-today`);
}

export default {
  getNotifications,
  updateNotifications,
};

var render = function render(){
  var _vm$options, _vm$options$message;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "420",
      "persistent": ""
    },
    model: {
      value: _vm.showAlertPopup,
      callback: function ($$v) {
        _vm.showAlertPopup = $$v;
      },
      expression: "showAlertPopup"
    }
  }, [_c('v-card', {
    class: ((_vm$options = _vm.options) === null || _vm$options === void 0 ? void 0 : (_vm$options$message = _vm$options.message) === null || _vm$options$message === void 0 ? void 0 : _vm$options$message.length) > 60 ? 'alert-pop --dense' : 'alert-pop'
  }, [_vm.options.message ? _c('v-card-text', {
    staticClass: "alert-txt"
  }, [_vm._v(_vm._s(_vm.options.message))]) : _vm._e(), _vm.options.html ? _c('v-card-text', {
    staticClass: "alert-txt"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.options.html)
    }
  })]) : _vm._e(), _c('v-card-actions', {
    staticClass: "alert-btns"
  }, [_vm.options.showBtnCancel ? _c('v-btn', {
    attrs: {
      "x-large": "",
      "color": "#7D7D7D"
    },
    on: {
      "click": _vm.clickCancel
    }
  }, [_vm._v(" 취소 ")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.clickOk
    }
  }, [_vm._v(" 확인 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
import abbreviate from 'number-abbreviate';
import helper from '@/utils/helper';
import constants from '@/utils/constants';

const toFixedFloat = function(src, resolution) {
  try {
    if (src === undefined) {
      return '-';
    }

    if (typeof src === 'string') {
      src = parseFloat(src);
    }

    const removeTailingZero = new RegExp(`\\.0{${resolution}}`, 'g');
    const strSrc = `${src.toFixed(resolution)}`;
    if (strSrc?.split('.')?.[0]?.length > 3) {
      return strSrc.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',').replace(removeTailingZero, '');
    }

    const resp = src.toFixed(resolution);
    if (isNaN(resp)) {
      return '0';
    }
    return resp.replace(removeTailingZero, '');
  } catch (err) {
    return src;
  }
};

export default {
  abbreviateNumber: function(src, resolution) {
    try {
      if (src === undefined) {
        return '0';
      }

      if (`${src}`?.indexOf('.') > 0) {
        return src?.toFixed(resolution);
      }

      if (resolution !== undefined) {
        return `${abbreviate(src, resolution)}`.toUpperCase();
      }

      return `${abbreviate(src)}`.toUpperCase();
    } catch (err) {
      return src;
    }
  },
  toFixedFloat: toFixedFloat,
  getCurrencyIcon: function(src, currency) {
    if (src === undefined) {
      return '';
    }

    return currency;
  },
  capitalize: function(src) {
    if (!src?.length) {
      return src;
    }

    return `${src?.[0]?.toUpperCase()}${src?.substring(1)}`;
  },
  osTypeForHuman: function(src) {
    return helper.getOsTypeForHuman(src);
  },
  convertDateFormat: function(srcDate, srcFormat, dstFormat) {
    return helper.convertDateFormat(srcDate, srcFormat, dstFormat);
  },
  convertAdNetworkName: function(src) {
    return constants.constantAdNetwork?.[src] ? constants.constantAdNetwork?.[src] : src;
  },
  convertUserStatus: function(src) {
    return constants.userStatusMap?.[src] ? constants.userStatusMap?.[src] : src;
  },
  convertAdunitId: function(src) {
    return constants.adunitIdMap?.[src] ? constants.adunitIdMap?.[src] : src;
  },
  hasFieldsValue: function(src) {
    try {
      const keysHaveValue = Object.keys(src).filter(key => {
        const value = src?.[key];
        return !['', undefined, null].includes(value);
      });
      return keysHaveValue?.length ? true : false;
      // eslint-disable-next-line no-empty
    } catch (err) {}
    return false;
  },
  getCountryName: function(src) {
    return constants.constantCountry?.[src] || `Unknown:(${src})`;
  },
  getSDKVersion: function(src) {
    return helper.getSDKVersion(src);
  },
  getState: function(latest, previous) {
    if (latest === 0) {
      return {
        direction: '',
        string: '',
      };
    }

    const diff = latest - previous;
    if (latest === 0 || previous === 0) {
      return {
        direction: '',
        string: `${toFixedFloat(diff, 0)}`,
      };
    }

    if (diff === 0) {
      return {
        direction: '',
        string: `${toFixedFloat(diff, 0)}`,
      };
    }

    const percent = (diff / previous) * 100;

    return {
      direction: percent > 0 ? 'fc-sb' : 'fc-r',
      string: `${diff > 0 ? '+' : ''}${toFixedFloat(diff, 2)} (${percent > 0 ? '+' : ''}${toFixedFloat(percent, 2)}%)`,
      percentString: `(${percent > 0 ? '+' : ''}${toFixedFloat(percent, 2)}%)`,
    };
  },
  stateString: function(src) {
    return src?.string;
  },
  stateDirectionClass: function(src) {
    return src?.direction;
  },
};

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('div', {
    staticClass: "lnb"
  }, [_c('div', {
    staticClass: "logo",
    on: {
      "click": _vm.onClickLogo
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo.png")
    }
  })]), _c('ul', {
    staticClass: "lnb-list"
  }, [_vm.getIsInhouseUser ? _vm._l(_vm.headerAdmin, function (menu) {
    return _c('li', {
      key: menu.label,
      class: menu.iconClass
    }, [_c('router-link', {
      attrs: {
        "to": menu.to
      }
    }, [_vm._v(_vm._s(menu.label))])], 1);
  }) : _vm._l(_vm.headerPartner, function (menu) {
    return _c('li', {
      key: menu.label,
      class: menu.iconClass
    }, [_vm.hasMenuPermission(menu.label) ? _c('router-link', {
      attrs: {
        "to": menu.to
      }
    }, [_vm._v(_vm._s(menu.label))]) : _vm._e()], 1);
  })], 2), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-width": 150,
      "content-class": "user-pop",
      "offset-x": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "profile"
        }, 'div', attrs, false), on), [_c('div', {
          staticClass: "mypage"
        }), _c('div', {
          staticClass: "user-id"
        }, [_c('v-btn', {
          attrs: {
            "dark": ""
          }
        }, [_c('div', {
          staticClass: "user-name"
        }, [_vm._v(_vm._s(_vm.getUserName))]), _c('div', [_vm._v(_vm._s('님'))])])], 1)])];
      }
    }]),
    model: {
      value: _vm.userMenu,
      callback: function ($$v) {
        _vm.userMenu = $$v;
      },
      expression: "userMenu"
    }
  }, [_c('v-card', [_c('v-list', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.getUserName))]), _c('v-list-item-subtitle', {
    staticClass: "user-email"
  }, [_vm._v(_vm._s(_vm.getUserEmail))])], 1)], 1)], 1), _c('v-list', {
    staticClass: "btn-list"
  }, [_c('v-list-item', {
    staticClass: "btn-myinfo",
    on: {
      "click": function ($event) {
        return _vm.redirectToMyInfo();
      }
    }
  }, [_c('v-icon', {
    staticClass: "icon-myinfo"
  }), _vm._v(" 내정보 ")], 1), _c('v-list-item', {
    staticClass: "btn-logout",
    on: {
      "click": _vm.logout
    }
  }, [_c('v-icon', {
    staticClass: "icon-logout"
  }), _vm._v(" 로그아웃 ")], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
import bus from '@/utils/bus';

export default {
  components: {},
  data: () => ({
    userMenu: false,
    headerAdmin: [
      {
        label: 'HOME',
        iconClass: 'ico-home',
        to: '/home',
      },
      {
        label: 'COMPANY',
        iconClass: 'ico-company',
        to: '/company',
      },
      {
        label: 'APPS',
        iconClass: 'ico-apps',
        to: '/apps',
      },
      {
        label: 'REPORT',
        iconClass: 'ico-report',
        to: '/report',
      },
      {
        label: 'MEDIATION',
        iconClass: 'ico-mediation',
        to: '/mediation',
      },
      {
        label: 'FINANCE',
        iconClass: 'ico-finance',
        to: '/finMonth',
      },
      {
        label: 'CAMPAIGN',
        iconClass: 'ico-campaign',
        to: '/campaign',
      },
      {
        label: 'SETTING',
        iconClass: 'ico-setting',
        to: '/setting',
      },
    ],
    headerPartner: [
      {
        label: 'REPORT',
        iconClass: 'ico-report',
        to: '/p/report',
      },
      {
        label: 'APPS',
        iconClass: 'ico-apps',
        to: '/p/apps',
      },
      {
        label: 'CAMPAIGN',
        iconClass: 'ico-campaign',
        to: '/p/campaign',
      },
      {
        label: 'FINANCE',
        iconClass: 'ico-finance',
        to: '/p/finance',
      },
      {
        label: 'SETTING',
        iconClass: 'ico-setting',
        to: '/p/account',
      },
    ],
  }),
  methods: {
    async logout() {
      if (this.getIsLoginMimic) this.logoutMimic();
      else {
        await this.$store.dispatch('LOGOUT');
        this.$router.push('/login').catch(() => {});
      }
    },
    async logoutMimic() {
      try {
        const redirectTo = await this.$store.dispatch('LOGOUTMIMIC');
        this.$router.push(redirectTo || '/').catch(() => {});
      } catch (err) {
        bus.openAlertPopup({ message: err?.stack });
      }
    },
    redirectToMyInfo() {
      this.userMenu = false;
      this.$router.push('/user').catch(() => {});
    },
    hasMenuPermission(menu) {
      return this?.$store?.getters.hasMenuPermission(menu?.toLowerCase());
    },
    onClickLogo() {
      if (this.getIsInhouseUser) this.$router.push('/home').catch(() => {});
      else this.$router.push('/p/report').catch(() => {});
    },
  },
  computed: {
    getUserName() {
      return this.$store.state?.name;
    },
    getUserEmail() {
      return this.$store.state?.email;
    },
    getIsInhouseUser() {
      return this.$store.getters.isInhouseUser;
    },
    getIsLoginMimic() {
      return this.$store.state?.isLoginMimic;
    },
  },
};
</script>

<template>
  <header>
    <div class="lnb">
      <div class="logo" @click="onClickLogo">
        <img src="@/assets/images/logo.png" />
      </div>
      <ul class="lnb-list">
        <template v-if="getIsInhouseUser">
          <li v-for="menu in headerAdmin" :key="menu.label" :class="menu.iconClass">
            <router-link :to="menu.to">{{ menu.label }}</router-link>
          </li>
        </template>
        <template v-else>
          <li v-for="menu in headerPartner" :key="menu.label" :class="menu.iconClass">
            <router-link v-if="hasMenuPermission(menu.label)" :to="menu.to">{{ menu.label }}</router-link>
          </li>
        </template>
      </ul>
      <v-menu v-model="userMenu" :close-on-content-click="false" :nudge-width="150" content-class="user-pop" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <div class="profile" v-bind="attrs" v-on="on">
            <div class="mypage"></div>
            <div class="user-id">
              <v-btn dark>
                <div class="user-name">{{ getUserName }}</div>
                <div>{{ '님' }}</div>
              </v-btn>
            </div>
          </div>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="user-name">{{ getUserName }}</v-list-item-title>
                <v-list-item-subtitle class="user-email">{{ getUserEmail }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list class="btn-list">
            <v-list-item class="btn-myinfo" @click="redirectToMyInfo()">
              <v-icon class="icon-myinfo"></v-icon>
              내정보
            </v-list-item>
            <v-list-item class="btn-logout" @click="logout">
              <v-icon class="icon-logout"></v-icon>
              로그아웃
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.logo {
  cursor: pointer;
}
</style>

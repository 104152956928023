import { instance } from './index';

function loginGoogle(userId, email, idToken) {
  return instance.post('dashboard/auth/login/google', {
    userId: userId,
    email: email,
    idToken: idToken,
  });
}

function logoutGoogle(userId, email, idToken) {
  return instance.post('dashboard/auth/logout/google', {
    userId: userId,
    email: email,
    idToken: idToken,
  });
}

function loginEmail(userData) {
  return instance.post('dashboard/auth/login/email', userData);
}

function loginMimic(companyId, userId) {
  return instance.post('dashboard/auth/login/mimic', {
    companyId,
    userId,
  });
}

function logout() {
  return instance.post('dashboard/auth/logout');
}

export default {
  loginGoogle,
  logoutGoogle,
  loginEmail,
  loginMimic,
  logout,
};

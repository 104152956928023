<script>
import { ref, computed } from 'vue';
export default {
  name: 'loadingPopup',
  components: {},
  props: ['value', 'message'],
  setup(props, { emit }) {
    const message = ref('로딩중입니다..');
    const isShow = computed({
      get: () => {
        if (props.message?.length) {
          message.value = props.message;
        } else {
          message.value = '로딩중입니다..';
        }
        return props.value;
      },
      set: value => {
        emit('input', value);
      },
    });

    return {
      isShow,
      message,
    };
  },
};
</script>

<template>
  <v-dialog v-model="isShow" max-width="420" persistent>
    <v-alert :value="true" class="loading-pop">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <div class="loading-txt">{{ message }}</div>
    </v-alert>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

import notificationApi from '@/api/notification';
import bus from '@/utils/bus';
import store from '@/store';
import constants from '../utils/constants';
import helper from '../utils/helper';
import { computed } from 'vue';

const getNotificationMessage = notifications => {
  const notificationTypes = notifications.map(n => n.notificationType);
  if (notificationTypes.includes('app_ads_unapplied') && notificationTypes.includes('app_ads_need_to_update')) {
    return 'app-ads.txt is unapplied or need to update';
  } else if (notificationTypes.includes('app_ads_unapplied')) {
    return 'app-ads.txt is unapplied';
  } else if (notificationTypes.includes('app_ads_need_to_update')) {
    return 'app-ads.txt need to update';
  }
  return '';
};

const showNotification = message => {
  bus.showSnackbar({
    title: message,
    timeout: -1,
    notification: true,
    showCloseBtn: true,
    eventCancel: () => {
      localStorage.setItem(`notificationUserId:${store.state.userId}`, helper.getKstNowWithFormat('YYYYMMDDHHmmss'));
    },
  });
};

export default {
  install(Vue) {
    Vue.prototype.$notification = async () => {
      bus.hideSnackbar();
      const isLogin = computed(() => store?.getters?.isLogin);
      const isAdmin = computed(() => store?.getters?.authority === 'admin');
      if (!isLogin.value || isAdmin.value) return;
      const notificationTime = localStorage.getItem(`notificationUserId:${store.state.userId}`);
      const expireAtBefore = helper.getKstNow().subtract(constants.notificationIntervalMinutes, 'minutes');
      if (notificationTime && expireAtBefore.isBefore(helper.getMomentWithFormat(notificationTime, 'YYYYMMDDHHmmss').add(9, 'hours'))) {
        return;
      }

      const resp = await notificationApi.getNotifications(store.state?.companyId);
      const notificationMessage = getNotificationMessage(resp?.data);
      if (notificationMessage?.length) {
        showNotification(notificationMessage);
      }
    };
  },
};

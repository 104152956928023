<script>
import { ref, computed } from 'vue';
export default {
  name: 'Snackbar',
  components: {},
  props: ['value', 'options'],
  setup(props, { emit }) {
    const options = ref({});
    const isShow = computed({
      get: () => {
        const propOptions = props.options || {};
        if (!props.options?.timeout) {
          propOptions.timeout = 1500;
        }
        if (props.options?.showCloseBtn === undefined) {
          propOptions.showCloseBtn = false;
        }

        if (props.options?.color === undefined) {
          propOptions.color = 'red accent-2';
        }

        if (props.options?.location === undefined) {
          propOptions.location = 'bottom';
        }

        options.value = propOptions;
        return props.value;
      },
      set: value => {
        emit('input', value);
      },
    });

    const onClickCloseBtn = () => {
      if (props.options?.eventCancel) {
        props.options?.eventCancel();
      }
      isShow.value = false;
    };

    return {
      isShow,
      options,
      onClickCloseBtn,
    };
  },
};
</script>

<template>
  <v-snackbar v-show="isShow" v-model="isShow" :timeout="options?.timeout" :color="options?.color" :location="options?.location" :value="true" :class="options?.notification && 'snackbar'" :right="options?.notification">
    {{ options?.title }}
    <router-link v-if="options?.notification" to="/p/apps" class="snackbar-link">Go to set app-ads.txt</router-link>
    <template v-if="options?.showCloseBtn" v-slot:action="{ attrs }">
      <v-btn icon dark class="btn-pop-close" @click="onClickCloseBtn" />
    </template>
  </v-snackbar>
</template>

<style lang="scss" scoped></style>

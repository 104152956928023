<script>
import bus from '@/utils/bus';

export default {
  name: 'ConfirmPopupCommon',
  components: {},
  setup(props) {
    function clickCancel() {
      if (props.options?.eventCancel) {
        props.options?.eventCancel();
      }
      bus.closeConfirmPopup();
    }

    function clickOk() {
      props.options?.eventOk();
      bus.closeConfirmPopup();
    }

    return {
      clickCancel,
      clickOk,
    };
  },

  props: {
    showConfirmPopup: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({
        show: false,
        message: '',
        hideCancelBtn: false,
        btnCancelTitle: '취소',
        vtnOkTitle: '확인',
        eventOkInterfaceName: '',
        eventCancelInterfaceName: '',
      }),
    },
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <v-dialog v-model="showConfirmPopup" max-width="420" persistent>
    <v-card class="alert-pop">
      <v-card-text class="alert-txt">{{ options.message }}</v-card-text>
      <v-card-actions class="alert-btns">
        <v-btn v-if="options?.hideCancelBtn !== true" x-large color="#7D7D7D" @click="clickCancel">
          취소
        </v-btn>
        <v-btn x-large color="primary" @click="clickOk">
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

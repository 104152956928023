var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "app"
    }
  }, [_vm.$route.meta.auth ? [!this.$route.meta.fullPage ? _c('Header') : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade-transition",
      "mode": "out-in",
      "duration": {
        enter: 100,
        leave: 100
      }
    }
  }, [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1), _vm.isShowSnackbar ? _c('Snackbar', {
    attrs: {
      "options": _vm.snackbarOptions
    },
    model: {
      value: _vm.isShowSnackbar,
      callback: function ($$v) {
        _vm.isShowSnackbar = $$v;
      },
      expression: "isShowSnackbar"
    }
  }) : _vm._e(), _vm.isLoginMimic ? _c('v-alert', {
    staticClass: "warning-mimic-login",
    attrs: {
      "type": "error",
      "dense": ""
    }
  }, [_vm._v("파트너 계정으로 로그인중입니다.")]) : _vm._e(), _vm.isShowLoadingPopup ? _c('LoadingPopup', {
    attrs: {
      "message": _vm.loadingPopupMessage
    },
    model: {
      value: _vm.isShowLoadingPopup,
      callback: function ($$v) {
        _vm.isShowLoadingPopup = $$v;
      },
      expression: "isShowLoadingPopup"
    }
  }) : _vm._e(), _c('AlertPopup', {
    attrs: {
      "showAlertPopup": _vm.showAlertPopup,
      "options": _vm.alertPopupOptions
    }
  }), _c('ConfirmPopup', {
    attrs: {
      "showConfirmPopup": _vm.showConfirmPopup,
      "options": _vm.confirmPopupOptions
    }
  })] : [_c('keep-alive', [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1), _c('AlertPopup', {
    attrs: {
      "showAlertPopup": _vm.showAlertPopup,
      "options": _vm.alertPopupOptions
    }
  })]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
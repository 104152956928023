import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    // login
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/partner/login',
      name: 'partnerlogin',
      component: () => import('@/views/PartnerLogin.vue'),
      meta: {
        auth: false,
      },
    },
    // error
    {
      path: '/errorNetwork',
      name: 'errorNetwork',
      component: () => import('@/views/error/ErrorNetwork.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/errorCheck',
      name: 'errorCheck',
      component: () => import('@/views/error/ErrorCheck.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/notFoundPage',
      name: 'notFoundPage',
      component: () => import('@/views/error/NotFoundPage.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/notAuthorized',
      name: 'notAuthorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {},
    },
    //component
    {
      path: '/curvechart',
      component: () => import('@/components/layout/CurveChart.vue'),
      meta: {
        auth: true,
      },
    },
    // user
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/user/MyPage.vue'),
      meta: {
        auth: true,
      },
    },

    // admin
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/admin/home/List.vue'),
      meta: {
        auth: true,
        isInhouse: true,
      },
    },
    {
      path: '/company',
      name: 'company',
      component: () => import('@/views/admin/company/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/apps',
      name: 'apps',
      component: () => import('@/views/admin/apps/ListPage.vue'),
      meta: {
        auth: true,
        isInhouse: true,
      },
    },
    {
      path: '/apps/:appId/company/:companyId',
      name: 'appsView',
      component: () => import('@/views/admin/apps/ViewPage.vue'),
      meta: {
        auth: true,
      },
      props: true,
    },
    {
      path: '/mediation',
      name: 'mediation',
      component: () => import('@/views/admin/mediation/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import('@/views/admin/setting/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/report',
      name: 'report',
      component: () => import('@/views/admin/report/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/DownloadBox',
      name: 'DownloadBox',
      component: () => import('@/views/admin/report/DownloadBox.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/invoiceA',
      name: 'invoiceA',
      component: () => import('@/views/admin/finance/Invoice_A.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/invoiceB',
      name: 'invoiceB',
      component: () => import('@/views/admin/finance/Invoice_B.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/invoiceC',
      name: 'invoiceC',
      component: () => import('@/views/admin/finance/Invoice_C.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/invoiceD1',
      name: 'invoiceD1',
      component: () => import('@/views/admin/finance/Invoice_D1.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/invoiceD2',
      name: 'invoiceD2',
      component: () => import('@/views/admin/finance/Invoice_D2.vue'),
      meta: {
        auth: false,
      },
    },
    // finance - 월별매츨
    {
      path: '/finMonth',
      name: 'finMonth',
      component: () => import('@/views/admin/finance/FinMonth.vue'),
      meta: {
        auth: true,
      },
    },
    // finance - 회사리포트
    {
      path: '/finCompany',
      name: 'finCompany',
      component: () => import('@/views/admin/finance/FinCompany.vue'),
      meta: {
        auth: true,
      },
    },
    // finance - 정산
    {
      path: '/finPayment',
      name: 'finPayment',
      component: () => import('@/views/admin/finance/FinPayment.vue'),
      meta: {
        auth: true,
      },
    },
    // finance - 명세서
    {
      path: '/finInvoice',
      name: 'finInvoice',
      component: () => import('@/views/admin/finance/FinInvoice.vue'),
      meta: {
        auth: true,
      },
    },
    // finance - 정산서 상세 내역
    {
      path: '/finPayment/detail',
      name: 'finPaymentDetail',
      component: () => import('@/views/admin/finance/FinPaymentDetail.vue'),
      meta: {
        auth: true,
        fullPage: true,
      },
    },
    // finance - 송금
    {
      path: '/finRemittance',
      name: 'finRemittance',
      component: () => import('@/views/admin/finance/FinRemittance.vue'),
      meta: {
        auth: true,
      },
    },
    // finance - 매출채권
    {
      path: '/finDeposit',
      name: 'finDeposit',
      component: () => import('@/views/admin/finance/FinDeposit.vue'),
      meta: {
        auth: true,
      },
    },
    // finance - 애드네트워크
    {
      path: '/finAdNetwork',
      name: 'finAdNetwork',
      component: () => import('@/views/admin/finance/FinAdNetwork.vue'),
      meta: {
        auth: true,
      },
    },
    // campaign - 캠페인 목록
    {
      path: '/campaign',
      name: 'campaign',
      component: () => import('@/views/admin/campaign/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    // campaign - 캠페인 상세
    {
      path: '/campaign/:campaignId',
      name: 'campaignView',
      component: () => import('@/views/admin/campaign/CampaignDetail.vue'),
      meta: {
        auth: true,
      },
    },
    // partner
    {
      path: '/p/apps',
      name: 'partnerApps',
      component: () => import('@/views/partner/apps/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/p/apps/:appId/company/:companyId',
      name: 'partnerAppsView',
      component: () => import('@/views/partner/apps/ViewPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/p/account',
      name: 'partnerAccount',
      component: () => import('@/views/partner/account/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/p/report',
      name: 'partnerReport',
      component: () => import('@/views/partner/report/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/p/finance',
      name: 'partnerFinance',
      component: () => import('@/views/partner/finance/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/p/finance/detail',
      name: 'partnerFinanceDetail',
      component: () => import('@/views/partner/finance/DetailPage.vue'),
      meta: {
        auth: true,
        fullPage: true,
      },
    },
    {
      path: '/p/campaign',
      name: 'partnerCampaign',
      component: () => import('@/views/partner/campaign/ListPage.vue'),
      meta: {
        auth: true,
      },
    },
  ],
});

const toLoginRedirect = to => ({
  path: '/login',
  params: {
    nextUrl: to.fullPath,
  },
});

const toNoAuthorizedRedirect = to => ({
  path: '/notAuthorized',
  params: {
    nextUrl: to.fullPath,
  },
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store?.state?.token ? true : false;
  const redirectUrl = store?.getters?.getRedirectUrl;

  if (to.path === '/') {
    next(redirectUrl);
  }

  if (isLoggedIn) {
    if (to?.path?.includes('login')) {
      return next(redirectUrl);
    }
  }

  if (!to?.meta?.auth) {
    return next();
  }

  if (!isLoggedIn) {
    return next(toLoginRedirect(to));
  }

  if (to?.meta?.isInhouse === true) {
    if (!['manager', 'admin'].includes(store?.state?.authority)) {
      return next(toNoAuthorizedRedirect(to));
    }
  }

  window.scrollTo(0, 0);

  next();
});

router.afterEach((to, from) => {
  Vue.prototype.$notification();
});

export default router;

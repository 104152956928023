var render = function render(){
  var _vm$options, _vm$options2, _vm$options3, _vm$options4, _vm$options5, _vm$options6, _vm$options7, _vm$options8;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShow,
      expression: "isShow"
    }],
    class: ((_vm$options = _vm.options) === null || _vm$options === void 0 ? void 0 : _vm$options.notification) && 'snackbar',
    attrs: {
      "timeout": (_vm$options2 = _vm.options) === null || _vm$options2 === void 0 ? void 0 : _vm$options2.timeout,
      "color": (_vm$options3 = _vm.options) === null || _vm$options3 === void 0 ? void 0 : _vm$options3.color,
      "location": (_vm$options4 = _vm.options) === null || _vm$options4 === void 0 ? void 0 : _vm$options4.location,
      "value": true,
      "right": (_vm$options5 = _vm.options) === null || _vm$options5 === void 0 ? void 0 : _vm$options5.notification
    },
    scopedSlots: _vm._u([(_vm$options6 = _vm.options) !== null && _vm$options6 !== void 0 && _vm$options6.showCloseBtn ? {
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', {
          staticClass: "btn-pop-close",
          attrs: {
            "icon": "",
            "dark": ""
          },
          on: {
            "click": _vm.onClickCloseBtn
          }
        })];
      }
    } : null], null, true),
    model: {
      value: _vm.isShow,
      callback: function ($$v) {
        _vm.isShow = $$v;
      },
      expression: "isShow"
    }
  }, [_vm._v(" " + _vm._s((_vm$options7 = _vm.options) === null || _vm$options7 === void 0 ? void 0 : _vm$options7.title) + " "), (_vm$options8 = _vm.options) !== null && _vm$options8 !== void 0 && _vm$options8.notification ? _c('router-link', {
    staticClass: "snackbar-link",
    attrs: {
      "to": "/p/apps"
    }
  }, [_vm._v("Go to set app-ads.txt")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
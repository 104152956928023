<script>
import bus from '@/utils/bus';
import Header from '@/components/layout/Header';
import Snackbar from '@/components/layout/Snackbar';
import LoadingPopup from '@/components/layout/LoadingPopup';
import AlertPopup from '@/components/layout/AlertPopupCommon.vue';
import ConfirmPopup from '@/components/layout/ConfirmPopup.vue';

export default {
  name: 'App',

  components: {
    Header,
    Snackbar,
    LoadingPopup,
    AlertPopup,
    ConfirmPopup,
  },

  data: () => ({
    spinnerCnt: 0,
    isShowLoadingPopup: false,
    loadingPopupMessage: '',
    loadingPopupOptions: {},
    loadfail: false,
    alert: false,
    noapp: false,
    isShowSnackbar: false,
    snackbarOptions: {},
    showAlertPopup: false,
    alertPopupOptions: {},
    showConfirmPopup: false,
    confirmPopupOptions: {},
  }),
  methods: {
    startSpinner(message) {
      this.spinnerCnt++;
      this.isShowLoadingPopup = true;
      this.loadingPopupMessage = message;
    },
    stopSpinner() {
      this.spinnerCnt--;
      if (this.spinnerCnt <= 0) {
        this.isShowLoadingPopup = false;
        this.spinnerCnt = 0;
      }
    },
    openAlertPopup(options) {
      this.alertPopupOptions = options;
      this.alertPopupOptions.showBtnOk = options.showBtnOk !== undefined ? options.showBtnOk : true;
      this.showAlertPopup = true;
    },
    closeAlertPopup() {
      this.showAlertPopup = false;
    },
    openConfirmPopup(options) {
      this.confirmPopupOptions = options;
      this.showConfirmPopup = true;
    },
    closeConfirmPopup() {
      this.showConfirmPopup = false;
    },
    showSnackbar(options) {
      this.isShowSnackbar = true;
      this.snackbarOptions = options;
    },
    hideSnackbar() {
      this.isShowSnackbar = false;
    },
  },
  created() {
    bus.vue.$on('start:spinner', this.startSpinner);
    bus.vue.$on('stop:spinner', this.stopSpinner);
    bus.vue.$on('open:alert:popup', this.openAlertPopup);
    bus.vue.$on('close:alert:popup', this.closeAlertPopup);
    bus.vue.$on('show:snackbar', this.showSnackbar);
    bus.vue.$on('hide:snackbar', this.hideSnackbar);
    bus.vue.$on('open:confirm:popup', this.openConfirmPopup);
    bus.vue.$on('close:confirm:popup', this.closeConfirmPopup);
  },
  beforeDestroy() {
    bus.vue.$off('start:spinner', this.startSpinner);
    bus.vue.$off('stop:spinner', this.stopSpinner);
    bus.vue.$off('open:alert:popup', this.openAlertPopup);
    bus.vue.$off('close:alert:popup', this.closeAlertPopup);
    bus.vue.$off('show:snackbar', this.showSnackbar);
    bus.vue.$off('hide:snackbar', this.hideSnackbar);
    bus.vue.$off('open:confirm:popup', this.openConfirmPopup);
    bus.vue.$off('close:confirm:popup', this.closeConfirmPopup);
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.spinnerCnt = 0;
      }
    },
  },
  computed: {
    isLoginMimic() {
      return this.$store.state?.isLoginMimic;
    },
  },
};
</script>

<template>
  <v-app id="app">
    <template v-if="$route.meta.auth">
      <Header v-if="!this.$route.meta.fullPage"></Header>
      <transition name="fade-transition" mode="out-in" :duration="{ enter: 100, leave: 100 }">
        <router-view :key="$route.fullPath" />
      </transition>
      <Snackbar v-if="isShowSnackbar" v-model="isShowSnackbar" :options="snackbarOptions" />
      <v-alert v-if="isLoginMimic" type="error" class="warning-mimic-login" dense>파트너 계정으로 로그인중입니다.</v-alert>
      <!-- [D]: notification 노출 유무 snackbar 속성 추가시 노출 -->
      <!-- 
			<ConfirmPopup :isConfirmAlert="alert" />

			<LoadFailPopup :isPopuploadfail="loadfail" />
			<NoAppPopup :isPopupNoApp="noapp" /> -->
      <LoadingPopup v-if="isShowLoadingPopup" v-model="isShowLoadingPopup" :message="loadingPopupMessage" />
      <AlertPopup :showAlertPopup="showAlertPopup" :options="alertPopupOptions" />
      <ConfirmPopup :showConfirmPopup="showConfirmPopup" :options="confirmPopupOptions" />
    </template>
    <template v-else>
      <keep-alive>
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
      <AlertPopup :showAlertPopup="showAlertPopup" :options="alertPopupOptions" />
    </template>
    <!--<Header v-show="!['guide', 'login', 'partInvoice', 'invoiceA', 'invoiceB', 'invoiceC', 'invoiceD1', 'invoiceD2'].includes($route.name)" />-->
  </v-app>
</template>

<style lang="scss">
@import '@/styles/style.scss';
@import '@/styles/variables.scss';
</style>

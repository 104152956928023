import moment from 'moment';

const getNowUTC = () => {
  return moment().utc();
};

const getMomentKSTWithFormat = (src, format) => {
  return moment(src, format)
    .utc()
    .add(9, 'hour');
};

const convertDateFormat = (srcDate, srcFormat, dstFormat) => {
  return moment(srcDate, srcFormat).format(dstFormat);
};

const getKstLastMonthFirstDayWithFormat = fmt => {
  return getKstNow()
    .subtract(1, 'month')
    .set('date', 1)
    .format(fmt);
};

const getKstLastMonthLastDayWithFormat = fmt => {
  return getKstNow()
    .set('date', 1)
    .subtract(1, 'day')
    .format(fmt);
};

const getWorkingDay = src => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // 양력 국가 공휴일
    if (['01-01', '03-01', '05-05', '06-06', '08-15', '10-03', '10-09', '12-31'].includes(src.format('MM-DD'))) {
      src.add(1, 'days');
      continue;
    }

    // 주말
    if ([0, 6].includes(src.weekday())) {
      src.add(1, 'days');
      continue;
    }

    break;
  }
  return src;
};

// 실제로는 1달 후 5일
const getDateM35WorkingDay = target => {
  if (!target) {
    target = getKstNow();
  }
  return getWorkingDay(target.add(1, 'month').date(5)).format('YYYY-MM-DD');
};

// 실제로는 2달 후 5일
const getDateM65WorkingDay = target => {
  if (!target) {
    target = getKstNow();
  }
  return getWorkingDay(target.add(2, 'month').date(5)).format('YYYY-MM-DD');
};

// 실제로는 2달 후
const getDateM60WorkingDay = target => {
  if (!target) {
    target = getKstNow();
  }
  return getWorkingDay(target.add(2, 'month')).format('YYYY-MM-DD');
};

const getKstRangeYearBeforeWithFormat = (amount, range, format) => {
  return {
    from: getKstNow()
      .subtract(amount, range)
      .format(format),
    to: getKstNow().format(format),
  };
};

const getKstRangeYearBeforeWithFormatForPartner = (amount, range, format) => {
  return {
    from: getKstNow()
      .subtract(amount, range)
      .subtract(1, 'days')
      .format(format),
    to: getKstNow()
      .subtract(1, 'days')
      .format(format),
  };
};

const getKstRangeLastMonth = fmt => {
  const startDate = getKstLastMonthFirstDayWithFormat(fmt);
  const endDate = getKstLastMonthLastDayWithFormat(fmt);
  return {
    startDate,
    endDate,
  };
};

const getKstRangeTargetMonth = (targetMonth, fmt) => {
  const startDate = targetMonth.set('date', 1).format(fmt);
  const endDate = targetMonth
    .add(1, 'month')
    .set('date', 1)
    .subtract(1, 'day')
    .format(fmt);
  return {
    startDate,
    endDate,
  };
};

const getKstNow = () => {
  return getNowUTC().add(9, 'hours');
};

const getWeekDayKorean = (src, format) => {
  const locales = ['일', '월', '화', '수', '목', '금', '토'];
  return locales?.[moment(src, format).day()];
};

const getKstNowWithFormat = formatString => {
  return getKstNow().format(formatString);
};

const getKstNowLastMonthWithFormat = formatString => {
  return getKstNow()
    .subtract(1, 'months')
    .format(formatString);
};

const getUtcWithFormat = (src, format) => {
  return moment(src).format(format);
};

const getKstWithFormat = (src, format) => {
  return moment(src)
    .utc()
    .add(9, 'hours')
    .format(format);
};

const getMomentWithFormat = (src, format) => {
  return moment(src, format);
};

const getColorFromString = src => {
  var hash = 0;
  for (var i = 0; i < src.length; i++) {
    hash = src.charCodeAt(i) + ((hash << 5) - hash);
  }
  var c = (hash & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
};

const RGBHexToRgbA = (hex, alpha) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    const rgbaString = 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${alpha !== undefined ? alpha : '1'})`;
    return rgbaString;
  }
  throw new Error('Bad Hex');
};

const getOsTypeForHuman = src => {
  if (src == 'aos') {
    return 'Android';
  } else if (src == 'aos_one') {
    return 'Android OneStore';
  } else if (src == 'aos_galaxy') {
    return 'Android Galaxy Store';
  } else if (src == 'ios') {
    return 'iOS';
  } else {
    return src;
  }
};

const getAppNameForChart = src => {
  return src && `${src?.split('/')?.[0]} / ${getOsTypeForHuman(src?.split('/')?.[1])}`;
};

const sanitizeEquation = src => {
  return src.replace(/[^-\d+.]/g, '');
};

const getInt = src => {
  if (!isNaN(src)) return parseInt(src, 10);
  if (!src?.replace) return 0;
  return parseInt(sanitizeEquation(src || 0), 10);
};

const getIntWithNegative = src => {
  const isNegative = src.match(/^-|\(.*?\)/g)?.length > 0;
  return getInt(src) * (isNegative ? -1 : 1);
};

const getFloat = src => {
  if (!isNaN(src)) return parseFloat(src, 10);
  if (!src?.replace) return 0;
  return parseFloat(sanitizeEquation(src || 0), 10);
};

const sleepMs = ms => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

const getSafeList = src => {
  if (!src) {
    return [];
  }

  // object 인 경우
  if (Object.keys(src).length === 0) return [];

  if (src?.length) return src;

  return [];
};

const getDictFromList = (list, key) => {
  if (!list?.length) {
    return {};
  }

  const resp = {};
  list.forEach(r => {
    if (r?.[key] === undefined) return;
    resp[r?.[key]] = r;
  });
  return resp;
};

const getDaysOnMonth = targetDate => {
  const endDay = moment(targetDate, 'YYYYMM').daysInMonth();
  const days = [];
  for (let i = 1; i <= endDay; i++) {
    days.push({
      dayString: `${i}일`,
    });
  }
  return days;
};

const getUniquifiedListOfObject = (src, key) => {
  try {
    const uniqueValues = new Set(src?.map(r => r?.[key]));
    const uniquifiedList = src?.filter(r => {
      if (uniqueValues.has(r?.[key])) {
        uniqueValues.delete(r?.[key]);
        return true;
      }
      return false;
    });
    return uniquifiedList;
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

const getRecentPeriods = (value, unit, format) => {
  const startDate = getKstNow()
    .subtract(value, unit)
    .format(format);
  const endDate = getKstNow().format(format);
  return { startDate, endDate };
};

const getRecentPeriodsForPartner = (value, unit, format) => {
  const startDate = getKstNow()
    .subtract(value, unit)
    .subtract(1, 'days')
    .format(format);
  const endDate = getKstNow()
    .subtract(1, 'days')
    .format(format);
  return { startDate, endDate };
};

const getParsedJsonSafe = src => {
  try {
    return JSON.parse(src);
  } catch (err) {
    return src;
  }
};

const removeReactive = src => {
  if (!src) return src;
  try {
    return JSON.parse(JSON.stringify(src));
  } catch (err) {
    return src;
  }
};

const getFilenameFromHeaders = (headers, defaultFilename) => {
  try {
    const filename = headers['content-disposition']
      .split(';')
      .filter(s => s.includes('filename'))[0]
      .split('=')[1]
      .replaceAll(/"/g, '');
    return decodeURI(filename);
  } catch (err) {
    return defaultFilename;
  }
};

const toBeEmptyList = (src, length) => {
  if (length === undefined) {
    return src;
  }

  if (src?.length === length) {
    return undefined;
  }

  return src;
};

const getSDKVersion = src => {
  if (!src?.sdk?.length) return '';
  const sortedSdks = [...src?.sdk];
  sortedSdks.sort((a, b) => {
    if (a.request > b.request) return -1;
    if (a.request < b.request) return 11;
    if (a.version > b.version) return -1;
    return 1;
  });
  const sdk = sortedSdks[0];
  const totalRequest = src.totalRequest;
  const sdkVersion = `${parseInt(sdk.version.slice(0, 2))}.${parseInt(sdk.version.slice(2, 4))}.${parseInt(sdk.version.slice(4, 6))}`;
  return `${sdkVersion} (${((sdk.request / totalRequest) * 100).toFixed(1)}%)`;
};

const getMaxQuantized = src => {
  if (src >= 100) {
    // 123 * 1.2 = 147.6 -> 148 -> position:1 => 15 + 1 * 10 => 160
    const position = `${Math.round(src)}`?.length - 2;
    return (Math.round(Math.round(src) / 10 ** position) + 1) * 10 ** position;
  } else if (src >= 10) {
    const position = `${Math.round(src)}`?.length - 1;
    return (Math.round(Math.round(src) / 10 ** position) + 1) * 10 ** position;
  } else if (src >= 1) {
    return 10;
  }

  return 1;
};

const getStepQuantized = src => {
  const stepRaw = Math.round(src / 6);
  const position = `${stepRaw}`?.length;
  const step = parseInt(`${stepRaw}`.split('')?.[0], 10) * 10 ** (position - 1);
  return step >= 1 ? step : 0.2;
};

const getChartRangeMinMax = rows => {
  const max = Math.max(...(rows || [])) * 1.1;
  const minOnLine = 0;
  const maxOnLine = getMaxQuantized(max);
  const stepSize = getStepQuantized(maxOnLine - minOnLine);
  return { minOnLine, maxOnLine, stepSize };
};

class Cycle {
  constructor() {
    this.index = -1;
    this.cycle = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  }
  current() {
    return this.cycle[this.index];
  }
  next() {
    return this.cycle[this.index >= 25 ? 0 : this.index++];
  }
}

const initCycle = () => {
  return new Cycle();
};

const deepCopy = obj => {
  return JSON.parse(JSON.stringify(obj));
};

const isEmptyObj = obj => {
  return JSON.stringify(obj) === '{}';
};

const isValidDateRange = (startDate, endDate) => {
  try {
    if (typeof startDate === 'string') startDate = new Date(startDate);
    if (typeof endDate === 'string') endDate = new Date(endDate);
    return startDate && endDate && startDate <= endDate;
  } catch (err) {
    return false;
  }
};

const dateAfterNMonths = (targetDate, n) => {
  const targetDateCopy = new Date(targetDate);
  return new Date(targetDateCopy.setMonth(targetDateCopy.getMonth() + n));
};

const sanitizeString = src => {
  // 소문자화, 공백 제거
  return src?.toLowerCase()?.replace(/\s/g, '');
};

const downloadFileFromResponse = response => {
  var blob = new Blob([response.data], { type: response.headers['content-type'] });
  var fileURL = window.URL.createObjectURL(blob);
  var fileLink = document.createElement('a');
  var fileName = getFilenameFromHeaders(response.headers, '.xlsx');
  fileLink.href = fileURL;
  fileLink.download = fileName;
  document.body.appendChild(fileLink);
  fileLink.click();
};

const fetchBlob = async (url, options) => {
  const response = await fetch(url, options);
  return await response.blob();
};

export default {
  getMomentKSTWithFormat,
  convertDateFormat,
  getUtcWithFormat,
  getKstWithFormat,
  getKstLastMonthFirstDayWithFormat,
  getKstLastMonthLastDayWithFormat,
  getKstNowLastMonthWithFormat,
  getDateM35WorkingDay,
  getDateM65WorkingDay,
  getDateM60WorkingDay,
  getKstRangeLastMonth,
  getKstRangeTargetMonth,
  getWeekDayKorean,
  getKstNow,
  getUniquifiedListOfObject,
  getKstRangeYearBeforeWithFormat,
  getKstRangeYearBeforeWithFormatForPartner,
  getKstNowWithFormat,
  getColorFromString,
  RGBHexToRgbA,
  getOsTypeForHuman,
  getAppNameForChart,
  sanitizeEquation,
  getInt,
  getIntWithNegative,
  getFloat,
  getDaysOnMonth,
  getRecentPeriods,
  sleepMs,
  getParsedJsonSafe,
  removeReactive,
  getFilenameFromHeaders,
  getSDKVersion,
  getChartRangeMinMax,
  getStepQuantized,
  initCycle,
  toBeEmptyList,
  getMomentWithFormat,
  getDictFromList,
  deepCopy,
  isEmptyObj,
  isValidDateRange,
  dateAfterNMonths,
  sanitizeString,
  downloadFileFromResponse,
  fetchBlob,
  getRecentPeriodsForPartner,
  getSafeList,
};
